@import 'src/styles/globalStyles';

.tiptap {
  margin: 0;
  border: 1px solid #c4c4c4;
  border-radius: $border-radius;
  padding: 10px;
  min-height: 150px;
  max-height: 300px;
  overflow: auto;

  p {
    margin-bottom: 0 !important;
  }
}
