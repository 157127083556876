.menu-item {
  background: rgba(240, 240, 240, 0.577);
  border-radius: 0.5rem;
  border: none;
  color: #2e2b29;
  margin: 4px 2px 0 0;
  padding: 4px 6px;
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);

  &:hover {
    background-color: rgba(61, 37, 20, 0.12);
  }

  &.is-active {
    background: #464646;
    color: white;
  }

  &:last-child {
    margin: 4px 0 0 0;
  }
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 16px;
  width: 16px;

  svg {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
