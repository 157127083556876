$primary: #f67121;
$primaryHover: #ff9c60db;
$secondary: #50c2d5;
$error: #f44336;
$warning: #ff9800;

$border-radius: 6px;

button.MuiButton-contained {
    color: white;
}