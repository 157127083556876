@import 'src/styles/globalStyles';

.extra-info-element {
  padding: 10px;
  background: rgba(224, 224, 224, 0.2);
  border-radius: $border-radius;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
