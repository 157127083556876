@import 'src/styles/globalStyles';

.divider {
  background: #464646;
  height: 20px;
  margin: 4px 4px 0 4px;
  width: 1px;
}

.menu {
  border-radius: $border-radius;
  background-color: transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: .3rem;
}
